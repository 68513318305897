export default {
    data() {
        return {
            locales: ['et', 'en'],
        }
    },
    methods: {
        switchLocale(locale) {
            if (!this.locales.filter(item => item === locale).length) {
                throw new Error(`Unknown locale ${locale}`);
            }
            if (this.$i18n.locale !== locale) {
                this.$i18n.locale = locale;
            }
        },
    },
}
